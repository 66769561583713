import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors, fonts } from "utils/variables"

// Layout
import Layout from "layouts/layout-primary"

// Components
import Container from "components/container/"
import SEO from "components/seo/"

const StyledContactUs = styled.section`
  padding: 60px 0;

  ${breakpoint.medium`
    padding: 88px 0; 
  `}

  .footer__item {
    margin-bottom: 24px;

    ${breakpoint.small`
      margin-bottom: 60px;

      &:last-child {
        margin-bottom: 0;
      }
    `}

    .title {
      font-family: ${fonts.secondary};
    }
  }

  .form-wrapper {
    padding: 32px 24px;
    background-color: ${colors.white};
    border-radius: 24px;

    ${breakpoint.medium`
      padding: 64px 88px;
    `}

    iframe {
      height: 824px;
    }
  }
`

const ContactUs = () => (
  <Layout showNewsletter={false}>
    <SEO
      title="Contact Us | Arena Analytics"
      description="Get in contact with the Arena Analytics team to learn more about our predictive analytics and AI recruitment solutions."
    />
    <StyledContactUs className="bg--silver__pale">
      <Container>
        <div className="row justify-content-between">
          <div className="col-12 col-sm-4 col-md-3">
            <div className="footer__item mt-sm-3">
              <h1 className="h1 mb-1 font-weight--700">Have questions?</h1>
              <p className="text--large">We're happy to help!</p>
            </div>

            <div className="footer__item">
              <p className="title mb-2 color--turquoise__primary font-weight--700">
                For general inquiries
              </p>

              <a
                href="mailto:info@arena.io"
                className="mb-2"
                target="_blank"
                rel="noopener  noreferrer"
              >
                info@arena.io
              </a>
              <br />
              <a
                href="tel:1-800-734-3630"
                target="_blank"
                rel="noopener  noreferrer"
              >
                1-800-734-3630
              </a>
            </div>

            <div className="footer__item">
              <p className="title mb-2 color--turquoise__primary font-weight--700">
                Press inquiries
              </p>

              <a
                href="mailto:press@arena.io"
                target="_blank"
                rel="noopener  noreferrer"
              >
                press@arena.io
              </a>
            </div>

            <div className="footer__item">
              <p className="title mb-2 color--turquoise__primary font-weight--700">
                Careers
              </p>

              <a
                href="mailto:careers@arena.io"
                target="_blank"
                rel="noopener  noreferrer"
              >
                careers@arena.io
              </a>
            </div>

            <div className="footer__item">
              <p className="title mb-2 color--turquoise__primary font-weight--700">
                Headquarters
              </p>

              <p>
                502 S. Sharp Street
                <br />
                Suite 2300
                <br />
                Baltimore, MD 21201
              </p>
            </div>
          </div>

          <div className="col-12 col-sm-8 col-md-78">
            <div className="form-wrapper">
              <p className="text--large">How can we help you?</p>

              <iframe
                src="https://arena-pardot.indicius.com/l/650953/2022-03-25/dz616"
                width="100%"
                title="Contact Us' form"
                type="text/html"
                frameBorder="0"
                style={{ border: "0" }}
              />
            </div>
          </div>
        </div>
      </Container>
    </StyledContactUs>
  </Layout>
)

export default ContactUs
